import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Rightcontainer from "../Components/Rightcontainer";
import "../css/terms.css";

const All_policy = () => {
  const [data, setData] = useState();
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    var baseUrl = beckendLiveApiUrl;
  }

  return (
    <div>
      <div
        className="leftContainer"
        style={{ minHeight: "100vh", height: "100%" }}
      >
        <div className="mt-5 py-4 px-3">
          <h3 className="w-100 mt-3">
            All Policies
          </h3>
          <ul
            style={{ fontSize: "16px" }}
            className="all_policy list-unstyled"
          >
            <div className="unstyled">
              <Link to="/term-condition">
                <li>Terms & Conditions</li>
              </Link>
              <Link to="/PrivacyPolicy">
                <li>Privacy policy</li>
              </Link>

              <Link to="/refund-policy">
                <li>Refund/Cancellatioin Policy</li>
              </Link>

              <Link to="/contact-us">
                <li>Contact Us</li>
              </Link>

              <Link to="/responsible-gaming">
                <li>Responsible Gaming </li>
              </Link>
              <Link to="About">
                <li>About Us</li>
              </Link>
            </div>
          </ul>
        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};
export default All_policy;
