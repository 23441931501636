import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import Rightcontainer from "../Components/Rightcontainer";
import "../css/layout.css";
import "../css/login.css";
import loginss from "../../assets/images/auth/ss.png";
import DeviceDetector from "device-detector-js";
import { Address4 } from "ip-address";
import CryptoJS from "crypto-js";
export default function Login() {
  const history = useHistory();
  const [Phone, setPhone] = useState();
  // const [Password, setPassword] = useState();
  const [twofactor_code, settwofactor_code] = useState();
  const [otp, setOtp] = useState(false);
  const [secretCode, setSecretCode] = useState();
  const [ipAddress, setIpAddress] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [referral, setReferral] = useState(
    useLocation().pathname.split("/")[2]
  );

  useEffect(() => {
    // Get IP address
    axios
      .get("https://api.ipify.org?format=json")
      .then((response) => {
        const ip = response.data.ip;
        const address = new Address4(ip);
        setIpAddress(address.address);
      })
      .catch((error) => {
        console.error("Error fetching IP address:", error);
      });

    const deviceDetector = new DeviceDetector();
    const userAgent = window.navigator.userAgent;
    const device = deviceDetector.parse(userAgent);
    setDeviceName(device.device.model || "Unknown Device");
  }, []);

  const handleClick = async (e) => {
    e.preventDefault();
    const PhoneTelNum = Phone;
    if (!PhoneTelNum) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter your phone",
      });
    } else if (Phone.length !== 10) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter a correct phone number",
      });
    } else {
      try {
        const generateRandomAlphanumeric = (length) => {
          const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
          let result = "";
          const charactersLength = characters.length;
          for (let i = 0; i < length; i++) {
            result += characters.charAt(
              Math.floor(Math.random() * charactersLength)
            );
          }
          return result;
        };

        const key =
          "0e70bbd9b919b94922f5b1e0394a0e0cfe040a5aecedbb8bbed536df5d64cf0e";
        const iv = "249ab4766ad60582c2b23c599a331a91";
        const data = JSON.stringify({
          PhoneTelNum,
          referral,
          ipAddress,
          deviceName,
        });
        const encryptedData = CryptoJS.AES.encrypt(
          data,
          CryptoJS.enc.Hex.parse(key),
          {
            iv: CryptoJS.enc.Hex.parse(iv),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
          }
        ).toString();

        const response = await axios.post(
          "https://apifrontstd.bazigarludoclub.com/user-login-send-tsats",
          {
            encryptedData,
          }
        );

        console.log("Response Data: ", response.data);

        const { status, msg, secret } = response.data;
        if (status === 101) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: msg,
          });
        } else if (status === 200) {
          console.log("Secret Code: ", secret);
          setOtp(true);
          setSecretCode(secret);
        }
      } catch (e) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong",
        });
        console.error(e);
      }
    }
  };

  const varifyOtp = async (e) => {
    // debugger;
    e.preventDefault();
    console.log("verify otp sumbut req");
    if (!Phone) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter your phone number",
      });
    } else {
      await axios
        .post(`https://apifrontstd.bazigarludoclub.com/login/finish`, {
          Phone,
          twofactor_code,
          referral,
          secretCode,
        })
        .then((respone) => {
          if (respone.data.status == 101) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: respone.data.msg,
            });
          } else if (respone.data.status == 200) {
            const token = respone.data.token;
            localStorage.setItem("token", token);
            window.location.reload(true);
            setTimeout(function () {
              history.push("/Games");
            }, 1000);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          }
        })
        .catch((e) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        });
    }
  };

  const setError = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Invalid Number",
      confirmation: true,
    });
  };
  return (
    <>
      <div className="leftContainer Login_page">
        <div className="main-area">
          <div className="Login_box center-xy ">
            <div className="d-flex center-xy font-15 mb-4  ">
              Sign in or sign up
              <p className="fw-bold">
                Fill your information and complete your account
              </p>
            </div>
            <div className="d-flex center-xy text-uppercase font-15">
              <div className="input-group mb-2">
                <div className="input-group-prepend">
                  <div className="input-group-text">+91</div>
                </div>
                <input
                  className="form-control"
                  name="mobile"
                  type="tel"
                  placeholder="MOBILE NUMBER"
                  onChange={(e) => {
                    setPhone(e.target.value);
                    if (e.target.value.length > 10) {
                      setError(true);
                    }
                  }}
                />
              </div>
            </div>
            {/* <div
                className="bg-whtie  cxy flex-column"
                style={{
                  marginTop: "30px",
                  width: "85%",
                  height: "auto",
                  backgroundColor: "#ffffff",
                  borderRadius: "5px",
                  border: "2px solid black",
                }}
              >
                <div
                  className="input-group mb-2 bg-white"
                  style={{ transition: "top 0.5s ease 0s", top: "5px" }}
                >
                 
                  <input
                    className="form-control bg-white"
                    name="password"
                    type="text"
                    placeholder="Enter Your Password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    style={{
                      transition: "all 3s ease-out 0s",
                      letterSpacing: "1px",
                      border: "none",
                      fontSize: "1px",
                    }}
                  />
                </div>
                </div> */}
            {otp && (
              <div className="cxy flex-column">
                <div
                  className="input-group mb-2"
                  style={{ transition: "top 0.5s ease 0s", top: "5px" }}
                >
                  <div className="input-group-prepend">
                    <div className="input-group-text">OTP</div>
                  </div>
                  <input
                    className="form-control"
                    name="password"
                    type="tel"
                    placeholder="Enter OTP"
                    onChange={(e) => settwofactor_code(e.target.value)}
                  />
                  {/* <div className="invalid-feedback">Enter a valid mobile number</div> */}
                </div>
              </div>
            )}
            {!otp && (
              <button
                className="Login-button cxy mt-4 w-100"
                onClick={(e) => handleClick(e)}
              >
                Get OTP
              </button>
            )}
            {otp && (
              <button
                className="Login-button cxy mt-4 w-100"
                onClick={(e) => varifyOtp(e)}
              >
                Verify
              </button>
            )}

            {/* <span
                style={{
                  color: "#050A30",
                  fontWeight: "700",
                  marginBottom: "10px",
                }}
                className="mt-4 "
              >
                Don't have an account? <a href="/register"> Register</a>
              </span> */}
          </div>
          <div className="login-footer">
            By continuing I agree that Fast baazigar ludo club Pvt. Ltd. may store and
            process my data in accordance with the{" "}
            <Link to="/term-condition">Terms of Use</Link>,{" "}
            <Link to="/PrivacyPolicy">Privacy Policy</Link> and that I am 18
            years or older. I am not playing from Assam, Odisha, Nagaland,
            Sikkim, Meghalaya, Andhra Pradesh, or Telangana.
          </div>
        </div>
      </div>
    </>
  );
}
