import React, { useEffect, useState } from "react";
import css from "../css/Mywallet.module.css";
import Rightcontainer from "../Components/Rightcontainer";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

const Mywallet = () => {
  const history = useHistory();
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  let access_token = localStorage.getItem("token");
  access_token = localStorage.getItem("token");
  const [user, setUser] = useState();
  useEffect(() => {
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      window.location.reload();
      history.push("/login");
    }
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUser(res.data);
      })
      .catch((e) => {
        console.log(e);
        if (e.response?.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          history.push("/login");
        }
      });
  }, []);

  return (
    <>
      <div className="leftContainer">
        <div className="px-4 mt-5 pt-4">
          <div className="main_area d-flex mt-3 justify-content-between align-items-center">
            <div className="back_btn">
              <Link to="/" className="btn">
                <i class="fa fa-arrow-left" aria-hidden="true"></i>Back
              </Link>
            </div>
            <div className="back_btn">
              <Link to="/transaction-history" className="btn">
                {/* <picture className="ml-4">
                <img
                  width="32px"
                  src={
                    process.env.PUBLIC_URL + "/Images/Header/order-history.png"
                  }
                  alt=""
                />
              </picture> */}
                Wallet History
              </Link>
            </div>
          </div>
          {/* <div className="back_btn my-3">
            <div className="wallet_box mt-0">
              <Link
                className={`boxes w-100 d-flex align-items-center justify-content-between`}
                to="/wallet"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <img
                    src="/Images/avatars/Avatar6.png"
                    alt="wallet"
                    width={"30px"}
                  />
                  <div
                    className={`${css.mytext} ml-2 text-white font-weight-bold`}
                  >
                    Verified
                  </div>
                  <i
                    class="fa fa-check-circle-o text-success ml-1"
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="btn bg-dark rounded">
                  Verification Completed
                </div>
              </Link>
            </div>
          </div> */}
        </div>
        {/* <div
          className={`${css.divider_x} XXsnipcss_extracted_selector_selectionXX snipcss0-0-0-1 tether-target-attached-top tether-abutted tether-abutted-top tether-element-attached-top tether-element-attached-center tether-target-attached-center`}
        ></div> */}

        <div className="px-4 mt-4">
          <div className="card bg-secondary">
            <div
              className="rounded p-2 d-flex"
              style={{ fontSize: "1.3em", fontWeight: "800" }}
            >
              Deposit Cash
              <div className="ml-2">
                <picture className="mr-1">
                  <img
                    width="30px"
                    src={
                      process.env.PUBLIC_URL +
                      "Images/LandingPage_img/global-rupeeIcon.png"
                    }
                    alt="Cash"
                  />
                </picture>
                <p
                  style={{ fontSize: "1.3em", fontWeight: "900" }}
                  className="d-inline"
                >
                  {user && user.Wallet_balance}
                </p>
              </div>
            </div>
            <div className="info p-2">
              Can be used to play Tournaments & Battles. Cannot be withdrawn to
              Paytm or Bank.
            </div>
            <div className="d-flex justify-content-center"></div>
            <Link to="/addcase" className="px-2 my-3">
              <button className="cash_btn text-white w-100 rounded font-weight-bold py-2 border-0">
                Add
              </button>
            </Link>
          </div>

          <div className="card mt-4 bg-secondary">
            <div
              className="rounded p-2 d-flex align-items-center"
              style={{ fontSize: "1.3em", fontWeight: "800" }}
            >
              Winning Cash
              <div className="d-inline ml-2">
                <picture className="mr-1">
                  <img
                    width="30px"
                    src={
                      process.env.PUBLIC_URL +
                      "Images/LandingPage_img/global-rupeeIcon.png"
                    }
                    alt="Cash"
                  />
                </picture>
                <p
                  style={{ fontSize: "1.3em", fontWeight: "900" }}
                  className="d-inline"
                >
                  {user && user.withdrawAmount}
                </p>
              </div>
            </div>
            <div className="info p-2">
              Can be withdrawn to Paytm or Bank. Can be used to play Tournaments
              & Battles.
            </div>
            <Link to="/Withdrawopt" className="px-2 my-3">
              <button className="cash_btn text-white w-100 rounded font-weight-bold py-2 border-0">
                Withdraw
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className="rightContainer">
        <div>
          <Rightcontainer />
        </div>
      </div>
    </>
  );
};
export default Mywallet;
