import React, { useEffect, useState } from "react";
import css from "../css/Refer.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
// import 'remixicon/fonts/remixicon.css'

const Refer = () => {
  const [user, setUser] = useState();
  const Cashheader = () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      // .get(`https://drtgort.rkbatting.com/me`, { headers })
      .get(`https://apifrontstd.bazigarludoclub.com/me`, { headers })
      .then((res) => {
        setUser(res.data);
        // console.log(res.data);
        Allgames(res.data.referral_code);
      })
      .catch((e) => {
        alert(e.msg);
      });
  };

  const [cardData, setGame] = useState([]);

  const Allgames = async (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      // .get(`https://drtgort.rkbatting.com/referral/code/${id}`, { headers })
      .get(`https://apifrontstd.bazigarludoclub.com/referral/code/${id}`, { headers })
      .then((res) => {
        setGame(res.data);
        console.log(res.data);
      });
  };

  useEffect(() => {
    Cashheader();
    //eslint-disable-next-line
  }, []);

  const copyCode = (e) => {
    // console.log(Game.Room_code);
    const referralLink = `https://baazigarludoclub.com/login/${user.referral_code}`;
    navigator.clipboard.writeText(referralLink);
    Swal.fire({
      position: "center",
      icon: "success",
      type: "success",
      title: "Room Code Copied",
      showConfirmButton: false,
      timer: 1200,
    });
  };

  if (user === undefined) {
    return null;
  }

  return (
    <div>
      <div className="leftContainer">
        <div className={`${css.center_xy} pt-5 mx-3`}>
          <picture className="mt-1">
            <img
              alt="img"
              width="226px"
              src={process.env.PUBLIC_URL + "Images/refer/refer1.png"}
              className="snip-img"
            />
          </picture>
          <div className="refer_box mb-1">
            <div className="font-15 text-center bg-secondary">
              Earn now unlimited
              <span aria-label="party-face">🥳</span>
            </div>
            <div className="d-flex justify-content-center font-weight-bold ">
              Refer your friends now!
            </div>
            {/* <div className="mt-3 text-center font-9">
              Current Earning:
              <b>
                {user.referral_wallet}
              </b>
              <Link className="ml-2" to="/Redeem">
                Redeem
              </Link>
            </div> */}
            <div className="d-flex justify-content-around my-2">
              <div className="border-right w-50 text-center">
                <div>Total Refers:</div>
                <b>{cardData && cardData}</b>
              </div>
              <div className="text-center font-9 w-50">
                <div>Total Earned:</div>
                <b>{user.referral_earning} ₹</b>
              </div>
            </div>

            {/* <div className={`${css.progress}`}>
              <div
                className={`${css.progress_bar} ${css.progress_bar_striped} ${css.bg_success}`}
                aria-valuenow={user.referral_earning}
                aria-valuemax={10000}
                style={{ width: `${(user.referral_earning * 200) / 10000}%` }}
              ></div>
            </div>
            <div className="font-9">
              <span>Max: 10,000</span>
              <Link className="float-right text-success" to="/update-pan">
                Upgrade Limit
              </Link>
            </div> */}
          </div>

          <div className={`${css.text_bold} mt-3 text-center refer_box`}>
            <div className="font-15 text-center bg-secondary mb-1">
              Referral Code
            </div>

            <div className="d-flex p-3 align-items-stretch">
              <h4 className="border-1 border w-100 text-left px-2 m-0 refer_code">
                {user.referral_code}
              </h4>
              <button
                className="cash_btn py-1 w-100"
                onClick={(e) => copyCode(e)}
              >
                Copy Code
              </button>
            </div>
          </div>

          <div className="refer_box mt-3">
            <div
              className={`${css.font_11} ${css.text_bold} bg-secondary p-2 text-center`}
            >
              Refer &amp; Earn Rules
            </div>
            <div className="d-flex align-items-center m-3">
              <picture>
                <img
                  alt="img"
                  width="82px"
                  src={process.env.PUBLIC_URL + "Images/refer/giftbanner.png"}
                  className="snip-img"
                />
              </picture>
              <div className={`${css.font_9} px-2`}>
                <div>
                  When your friend signs up on BaazigarLudo Club from your
                  referral link,
                </div>
                <div className={`${css.font_8} ${css.c_green} mt-2`}>
                  You get
                  <strong>&nbsp; 2% Commission</strong>
                  &nbsp;on your
                  <strong>&nbsp; referral's winnings.</strong>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center p-3 border-bottom border-secondary">
              <picture>
                <img
                  alt="img"
                  width="82px"
                  src={process.env.PUBLIC_URL + "Images/refer/banner.png"}
                  className="snip-img"
                />
              </picture>
              <div className={`${css.font_9} px-2`}>
                <div>
                  Suppose your referral plays a battle for a 10000 Cash,
                </div>
                <div className={`${css.font_8} ${css.c_green} mt-2`}>
                  You get
                  <strong> 200 Cash</strong>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`${css.refer_footer} py-3`}>
          <a
            href={`whatsapp://send?text=Play Ludo and earn Rs10000 daily.Commission Charge - 5% OnlyReferral - 3% On All Games.24x7 whats app Chat Support Instant Withdrawal Via UPI/Bank Register Now, My refer code is    👇  ${user.referral_code}.
     👇
     https://bazigarludoclub.com/login/${user.referral_code}`}
          >
            <div className="bg-green text-white rounded referbtn_refer cxy w-100 p-2">
              Share on Whatsapp
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Refer;
